<template>
  <div class="container">
    <!-- <el-backtop > </el-backtop> -->
    <div class="right"
         style="width: 100%">
      <div class="back"
           @click="back">
        <el-button type="primary"
                   size="mini"
                   style="position:absolute;top:230%;left:6%;z-index:11">返回</el-button>

      </div>

      <div class="produce">

        <div class="produce_left">
          <div style="font-size: 18px">{{ date1 }}</div>
          <div style="width: 20px; height: 2px; background: #000; margin: 4px auto"></div>
          <div style="font-size: 24px">{{ date2 }}/{{ date3 }}</div>
          <div style="width: 20px; height: 2px; background: #000; margin: 4px auto"></div>

          <div style="font-size: 18px">{{ date4 }}</div>
        </div>

        <div class="produce_right">
          <div class="ntitle">{{data.ntitle}}</div>
          <div v-html="data.ncontent"></div>
          <div class="source"
               style="font-size: 14px"
               v-if="data.nsource">
            新闻出处：
          </div>
          <div class="source_text"
               v-if="data.nsource">
            <!-- <a href="http://mz.hangzhou.gov.cn/art/2020/10/12/art_1597339_58923489.html"></a> -->
            <div @click="openLink"
                 style="font-size: 14px">
              {{ data.nsource }}
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { getnewDetail } from "../../utils/api.js";

export default {
  data () {
    return {
      id: "",
      data: {
        // ncreateTime: "2021-10-12 09:38",
      },
      date1: "",
      date2: "",
      date3: "",
      date4: "",
      nav_show: false,
      fullscreen: false,
    };
  },
  // created() {
  //   this.date1=this.data.ncreateTime.substr(0,4)
  //   this.date2=this.data.ncreateTime.substr(5,2)
  //   this.date3=this.data.ncreateTime.substr(8,2)
  //   this.date4=this.data.ncreateTime.substr(10,10)

  //   console.log(this.data.date.substr(0,4));
  //   console.log(this.data.date.substr(5,2));
  //   console.log(this.data.date.substr(8,2));

  //   console.log(this.data.date.substr(10,10));

  //   // console.log(this.$route.query.id);
  //   // if (this.$route.query.id) {
  //   //   this.id = this.$route.query.id;
  //   //   this.getnewDetail();
  //   // }
  // },
  activated () {
    console.log(this.$route.query.id);
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getnewDetail();
    }
  },
  created () {
    console.log(this.$route.query.id);
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getnewDetail();
    }
  },

  methods: {

    handleFullScreen () {
      if (new Date().getTime() - this.clickTtime < 500) {
        // console.log("双击");
        this.fullscreen = !this.fullscreen;
      } else {
        this.clickTtime = new Date().getTime();
        // console.log("单机")
      }
    },
    getnewDetail () {
      getnewDetail({ nId: this.id }).then((res) => {
        if (res.code == 20000) {
          console.log(res);
          // res.data.npictureUrl = JSON.parse(res.data.npictureUrl);
          this.data = res.data;
          this.date1 = this.data.ncreateTime.substr(0, 4);
          this.date2 = this.data.ncreateTime.substr(5, 2);
          this.date3 = this.data.ncreateTime.substr(8, 2);
          this.date4 = this.data.ncreateTime.substr(10, 10);
        }
      });
    },
    openLink () {
      window.open(this.data.nsource);
    },
    back () {
      this.$router.go(-1);
    },
    handleScroll () {
      // 页面滑动的距离
      let scrollTop = document.documentElement.scrollTop
      // console.log(scrollTop)
      // 当页面滑动的距离大于300px时元素显示，否则不显示
      if (scrollTop >= 100) {

        this.nav_show = false
      } else {
        this.nav_show = false
      }
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  // 组件销毁前
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  }
};
</script>

<style  scoped>
.container {
  background: #fff;
  width: 100%;
  min-height: 120vh;
}

.right {
  width: 100%;
  min-height: 120vh;
  background: #fefefe;
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px 0;
  box-sizing: border-box;
  z-index: 999;
}
.back {
  width: 100%;
  height: 30px;
  line-height: 30px;
  color: #000;
  border-radius: 10px;
  display: flex;
  align-items: center;
  background: #fff;

  position: relative;
}
.produce {
  width: 90%;
  padding: 40px 60px;
  margin: 20px auto;
  min-height: auto;
  box-sizing: border-box;
  background: #fefefe;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.produce_left {
  width: 15%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.produce_left div {
  margin-top: 5px;
}
.produce_right {
  width: 82%;
  /* text-align:center */
}
.source {
  /* width:500px; */
  text-align: left;
  font-size: 14px;
  color: #5a5a5a;
  font-style: normal;
}
.source_text div {
  width: 500px;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
}
.ntitle {
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: 550;
  margin-bottom: 10px;
}
</style>